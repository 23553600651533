import en from '~/lang/en.json'
import de from '~/lang/de.json'
import it from '~/lang/it.json'
import keys from '~/lang/keys.json'
import { defineI18nConfig } from '#i18n'

const addMessages = () => {
  const isProduction = process.env.NUXT_PUBLIC_VERCEL_ENV === 'production'
  const keysMessages = isProduction ? {} : keys

  return {
    en,
    de,
    it,
    keys: keysMessages
  }
}

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: addMessages(),
  warnHtmlMessage: false
}))
